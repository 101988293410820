<template>
  <div v-if="!loading && !loadingActiveLanguages" class="alert-container">
    <a-alert
      v-if="formBuilderChanged && enumerators && enumerators.length > 0 && !isTranslationMissing"
      class="sticky-alert"
      :description="createQuestionnaireMessage"
      type="info"
      closable
      :after-close="closeFormBuilderChangeAlert"
      show-icon/>
    <a-alert
      v-else-if="formBuilderChanged && (!surveyEnumerators || surveyEnumerators.length === 0) && isTranslationMissing"
      class="sticky-alert orange-alert"
      type="warning"
      :message="$t('components.notifications.formWasCreatedButTranslationMissing')"
      :description="$t('components.notifications.youMustProvideMissingTranslationsUsingTemplates')"
      closable
      show-icon>
        <template #icon>
          <a-icon type="info-circle" :style="{ fontSize: '25px', color: '#e98300' }" theme="filled"/>
       </template>
      </a-alert>
    <a-alert
      v-else-if="formBuilderChanged && surveyEnumerators && surveyEnumerators.length > 0 && isTranslationMissing"
      class="sticky-alert orange-alert"
      type="warning"
      :message="$t('components.notifications.formWasCreatedButTranslationMissing')"
      :description="$t('components.notifications.questionnaireUpdatedYouMustProvideMissingTranslations')"
      closable
      show-icon>
        <template #icon>
          <a-icon type="info-circle" :style="{ fontSize: '25px', color: '#e98300'}" theme="filled"/>
       </template>
    </a-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import enumerators from '../../mixins/enumerators';

export default {
  name: 'FormCreationAlert',
  mixins: [enumerators()],
  data() {
    return {
      createQuestionnaireMessage: this.$t('components.description.TheQuestionnaireHasBeenChanged'),
      activeAlerts: []
    };
  },
  computed: {
    ...mapState({
      formBuilderChanged: state => state.surveyForms.formBuilderChanged,
      activeLanguages: state => state.indicators.activeLanguages,
      loading: state => state.survey.loading,
      loadingActiveLanguages: state => state.indicators.loadingActiveLanguages
    }),
    isTranslationMissing() {
      let missing = false;
      if (this.activeLanguages && this.activeLanguages.length > 1) {
        missing = this.activeLanguages.some((lang) => (lang.missingFields && lang.missingFields.length > 0));
      }
      return missing;
    }
  },
  methods: {
    ...mapActions([
      'acknowledgeFormUploadChange',
      'ackFormBuilderAsChange'
    ]),
    getAlertIndex(id) {
      const index = this.activeAlerts.findIndex(item => item.id === id);
      return index;
    },
    closeFormBuilderChangeAlert() {
      this.ackFormBuilderAsChange();
    }
  }
};
</script>
<style lang="scss" scoped>

.sticky-alert {
  margin-top: 30px;
  align-content: center;
  min-width: 70%;
  width: auto;
  max-width: 100%;
  margin-right: 24pt;
}

.orange-alert > .ant-alert-message {
  font-weight: bold;
  font-family: 'DINNextLTPro-Medium';
}

.orange-alert > .ant-alert-description {
  color: #363534;
  line-height: 24px;
  font-size: 16px;
}

.orange-alert {
  border: solid 1px #e98300;
  background-color: #fdf3e5;
}

.alert-container {
  position: fixed;
  z-index: 1005;
  width: 67%;
}
</style>
